import "./src/styles/global.css";
import React from "react";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";

export const wrapRootElement = ({ element }) => (
  <KindeProvider
    clientId={process.env.GATSBY_KINDE_CLIENT_ID}
    domain={process.env.GATSBY_KINDE_DOMAIN}
    logoutUri={process.env.GATSBY_KINDE_LOGOUT_URL}
    redirectUri={process.env.GATSBY_KINDE_REDIRECT_URL}
  >
    {element}
  </KindeProvider>
);
